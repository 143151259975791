/* ImageSlider.css */
.image-slider-container {
    position: relative;
    width: 100%;
}

.line-progress {
    height: 6px !important;
    border-radius: 10px !important;
    background-color: var(--border-color) !important;
    .filled {
        border-radius: 10px;
        background-color: var(--primary-color);
        top: 0;
        height: 6px;
    }
}

@keyframes expand {
    0% {
        width: 0%; /* Start with 0% width */
    }
    100% {
        width: 100%; /* End with 100% width */
    }
}

.custom-dot-text {
    min-width: 200px;
    max-width: 300px;
    text-align: left;
    color: #ffffff;
    margin-top: 0.5rem;
}

.slider {
    width: 100%;
}

.slick-slide {
    padding: 0;
    font-size: 30px;
    text-align: center;
    height: 700px;
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        background: linear-gradient(to top, #000 -50%, rgba(0, 0, 0, 0) 50%);
    }
    > div {
        width: 100%;
        height: 100%;
    }
    .slide-data {
        height: 100%;
        .slide-bg {
            width: 100%;
            display: inline-block;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
        }
        .slide-content-container {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                bottom: 0;
                background: linear-gradient(to right, #000 -5%, rgba(0, 0, 0, 0) 50%);

                @media only screen and (max-width: 600px) {
                    background: linear-gradient(to right, #000 -5%, rgba(0, 0, 0, 0) 150%);
                }
            }
        }
        .slide-content {
            max-width: 600px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 1rem;
            align-items: start;
            text-align: left;
            z-index: 2;
            position: absolute;
            .title {
                color: #ffffff;
            }
            .desc {
                color: #dedede;
            }
        }
    }
}

.slick-dots {
    bottom: 70px;
    display: flex !important;
    align-items: center;
    width: 100%;
    justify-content: center;
    li {
        width: unset !important;
        padding: 0 1rem !important;
        &.slick-active {
            .filled {
                width: 100%;
                animation: expand 5s linear;
            }
        }
    }
    @media only screen and (max-width: 600px) {
        // flex-direction: column;
        li {
            padding: 0 !important;
            .custom-dot-text {
                // display: none;
                visibility: hidden;
                max-width: 10px;
                min-width: 10px;
            }
            &.slick-active {
                .custom-dot-text  {
                    max-width: 10px;
                    min-width: 30px !important;
                }
            }
        }
        // background: linear-gradient(to right, #000 -5%, rgba(0, 0, 0, 0) 150%);
    }
}
