.blog-card {
    background-color: var(--base-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    .content {
        display: flex;
        flex-direction: column;
        .title {
            color: var(--text-max);
            text-decoration: none;
        }
        .blog-text {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
            line-height: 1.5em;
            min-height: 4.5em;
        }
    }
    .poster-container {
        height: 250px;
        border-radius: 4px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
  }