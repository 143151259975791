#header {
    z-index: 400;
    // padding: 16px 0;
    padding: 0.25rem 0;
    background-color: var(--base-color);
    position: sticky;
    top: 0;
    border-bottom: 1px solid var(--border-color);
    // box-shadow: 0px 0px 10px 0px var(--bg-max);
    .logo {
        max-width: 110px;
        a {
            text-decoration: none;
            color: var(--primary-color);
        }
        img {
            width: 100%;
        }
    }

    // .language-seletor-input {
    //     background-color: transparent;
    //     border: 1px solid var(--primary-purple);
    //     height: 2.5rem;
    //     &:focus {
    //         border: 1px solid var(--primary-purple);
    //         box-shadow: none;
    //     }
    // }
}

// .active {
//     color: var(--primary-purple) !important;
// }

.nodis-menu {
    display: flex;
    align-items: center;
    .menu-item {
        padding: 0.5rem 1rem;
        text-decoration: none;
        color: var(--text-max);
        display: flex;
        align-items: center;
        transition: 0.3s;
        font-weight: normal;
        font-size: 1rem;
        &:hover {
            color: var(--primary-color) !important;
        }
    }
    a.active {
        color: var(--primary-color);
    }
}

.drop-down {
    position: relative;
    .menu-item {
        cursor: pointer;
        // &::after {
        //     content: "\f107";
        //     font-family: FontAwesome;
        //     padding-left: 5px;
        // }
    }
    ul {
        display: block;
        position: absolute;
        left: 0;
        top: calc(100% - -10px);
        z-index: 99;
        opacity: 0;
        visibility: hidden;
        // padding: 10px 0;
        background: var(--base-color);
        border-radius: var(--default-border-radius);
        // box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
        box-shadow: 0px 0px 30px var(--shadow-color);
        // border-top-right-radius: 20px;
        padding: 10px 20px;
        // transition: ease all 0.3s;
        li {
            min-width: 100px;
            position: relative;
            padding: 5px 0px;
            font-size: var(--fs-md);
            text-transform: none;
            cursor: pointer;
            .item {
                text-decoration: none;
                &:hover {
                    // filter: brightness(0.40);
                    color: var(--primary-purple);
                    transition: ease all 0.3s;
                }
            }
        }
    }
    &:hover {
        // color: var(--primary-purple);
        ul {
            opacity: 1;
            top: 100%;
            visibility: visible;
            transition: ease all 0.3s;
            list-style-type: none;
        }
    }
}

.mobile-menu {
    .navigate {
        margin-top: 3px !important;
        margin-bottom: 0 !important;
    }
    .header-menu-toggle {
        top: 10px !important;
    }
    .nav-close {
        top: 20px !important;
        right: 35px !important;
    }
    .menu-item {
        text-decoration: none;
        color: var(--text-color);
        font-weight: normal;
        font-size: 1rem;
    }
}
