@import "node_modules/bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");
@import "react-toastify/dist/ReactToastify.css";
@import "fancy-navbar.scss";

:root {
  --fs-xs: 10px;
  --fs-sm: 12px;
  --fs-md: 14px;
  --fs-lg: 16px;
  --fs-xl: 20px;
}

.dark-theme {
  --body-color: #141a20;
  --base-color: #11161a;
  --hover-color: #192027;
  --footer-bg: #051723;
  --hover-transparent: #192027b3;

  --text-color: #919eab;
  --text-max: #ffffff;
  --text-invert: #000000;
  --text-dim: #8e8ea0;

  --border-color: #2f3a46;
  --border-dim: #232536;

  --primary-color: #d81f28;
  --success-color: #18cc49;
  --warning-color: #ff9f2d;
  --danger-color: #f74241;
  --info-color: #1890ff;
}

.light-theme {
  --body-color: #ffffff;
  --base-color: #fafafa;
  --hover-color: #f4f4f4;
  --footer-bg: #071f2f;
  --hover-transparent: #f4f4f4b3;

  --text-color: #71717a;
  --text-max: #000000;
  --text-invert: #FFFFFF;
  --text-dim: #8e8ea0;

  --border-color: #e5eaf2;
  --border-dim: #e4e4e7;

  --primary-color: #d81f28;
  --success-color: #00ba34;
  --warning-color: #f98600;
  --danger-color: #e92d2b;
  --info-color: #0184ff;
}

// html {
//   font-size: 10px;
// }

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  // font-size: 14px;
  color: var(--text-color);
  background: var(--body-color);
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  overflow-x: hidden;
}

.fit-content {
  width: fit-content !important;
}

.primary {
  color: var(--primary-color);
}

.success {
  color: var(--success-color);
}

.warning {
  color: var(--warning-color);
}

.danger {
  color: var(--danger-color);
}

.fw-medium {
  font-weight: 500 !important;
}

.dim-text {
  color: var(--text-dim) !important;
}

.max-text {
  color: var(--text-max) !important;
}

hr {
  border-color: var(--border-color) !important;
  opacity: 1;
}

.pointer {
  cursor: pointer !important;
}

.capitalize {
  text-transform: capitalize !important ;
}

.underline {
  text-decoration: underline;
}

.nodis-btn {
  font-size: 14px;
  border-radius: 4px;
  transition: 0.2s ease-in-out;
  color: var(--text-color);
  position: relative;
  border: 1px solid transparent;
  &.success {
    border-color: var(--border-color);
    color: var(--success-color);
    background-color: var(--hover-color);
    font-weight: 500;
    &:hover {
      color: var(--success-color);
      background-color: var(--border-color);
    }
  }
  &.danger {
    border-color: var(--border-color);
    color: var(--danger-color);
    background-color: var(--hover-color);
    font-weight: 500;
    &:hover {
      color: var(--danger-color);
      background-color: var(--border-color);
    }
  }
  &.info {
    border-color: var(--border-color);
    color: var(--info-color);
    background-color: var(--hover-color);
    font-weight: 500;
    &:hover {
      color: var(--info-color);
      background-color: var(--border-color);
    }
  }
  &.outline {
    border-color: var(--border-color);
    background-color: transparent;
  }
  &.small {
    padding: 0.25rem;
  }
  // &.hover-glow {
  //   &:hover {
  //     box-shadow: 0px 1px 6px 0px rgba(0, 89, 178, 1);
  //   }
  // }
  &:hover {
    background-color: var(--hover-color);
    color: var(--text-max);
  }
}

.button-loader {
  backdrop-filter: blur(4px);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--base-color);
  border-radius: 4px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    filter: brightness(2);
  }
}

.lazy-loader {
  display: flex;
  height: 100vh;
  svg {
    width: 40px;
  }
}

.section-header {
  .title {
    color: var(--text-max);
  }
}

.section-data {
  background-color: var(--base-color);
  // padding: 1rem;
  border-radius: 5px;
  /* box-shadow: 0px 0px 10px 2px var(--border-dim); */
  border: 1px solid var(--border-color);
  .title {
    font-size: var(--fs-lg);
    color: var(--text-max);
  }
}

.render-content {
  p {
    margin-bottom: 0 !important;
  }
}

.min-h-250 {
  min-height: 250px;
}

.min-h-200 {
  min-height: 200px;
}

.min-h-150 {
  min-height: 150px;
}

.min-h-100 {
  min-height: 100px;
}

.min-h-12 {
  min-height: 12px !important;
}

.w-225 {
  width: 225px;
  flex-shrink: 0;
}

.w-150 {
  width: 150px;
  flex-shrink: 0;
}

.sq-60 {
  width: 60px;
  height: 60px;
}

.sq-200 {
  width: 200px;
  height: 200px;
}

.min-w-200 {
  min-width: 200px;
}

.mt-12 {
  margin-top: -12px !important;
}

.nodis-table {
  width: 100%;
  font-size: 14px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  overflow: hidden;
  &.out-border-0 {
    border: 0px;
    border-bottom: 1px !important;
    // tbody > tr:last-child > * {
    //   border-bottom: 1px !important;
    // }
  }
  &.condensed {
    th {
      padding: 5px 1rem !important;
      font-size: 13px;
    }
    td {
      padding: 5px 1rem !important;
      font-size: 13px;
      // color:greenyellow;
    }
    // tbody > tr:last-child > * {
    //   border-bottom: 1px !important;
    // }
  }
  .index {
    max-width: 50px;
    width: 50px;
    border-right: 1px solid var(--border-color);
    text-align: center;
  }
  .actions {
    max-width: 350px;
    width: 350px;
    // border-left: 1px solid var(--border-color);
    // text-align: center;
  }
  th {
    color: var(--text-dim) !important;
    padding: 0.75rem;
    vertical-align: middle;
    font-weight: normal;
    border-bottom-color: var(--border-color);
    background-color: var(--base-color);
  }
  td {
    padding: 0.75rem;
    vertical-align: middle;
    border-bottom: 1px solid var(--border-color);
    color: var(--text-color) !important;
    background-color: var(--base-color);
    // border-bottom-color: var(--border-color);
    > a {
      color: var(--text-color);
      text-decoration: none;
    }
    .title {
      color: var(--text-max);
    }
  }
  tbody > tr:last-child > * {
    // border-bottom: 0px;
  }
  // tbody > tr:nth-of-type(odd) > * {
  //   background-color: transparent !important;
  //   --bs-table-bg-type: transparent !important;
  //   --bs-table-accent-bg: transparent !important;
  // }
  // tbody > tr:nth-of-type(odd) > * {
  //   background-color: var(--base-color) !important;
  //   --bs-table-bg-type: var(--base-color) !important;
  // }
}

.form-label {
  font-size: 14px !important;
}

.nodis-field {
  border: 1px solid var(--border-color) !important;
  background-color: var(--hover-color) !important;
  border-radius: 4px !important;
  color: var(--text-max) !important;
  font-size: 14px;
  &::placeholder {
    color: var(--text-dim);
    // color: green;
  }
  &:disabled {
    color: var(--text-color) !important;
    background-color: var(--border-color) !important;
    cursor: not-allowed;
  }
}

.nodis-editor {
  .ql-toolbar {
    border: 1px solid var(--border-color) !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    .ql-picker-label{
      // transition: .3s ease-in-out;
      &.ql-active {
        color: var(--primary-color) !important;
      }
      &:hover {
        color: var(--text-max) !important;
      }
    }
    .ql-formats {
      button {
        &:hover {
          .ql-stroke {
            stroke: var(--text-max) !important;
          }
          .ql-fill {
            fill: var(--text-max) !important;
          }
        }
      }
      .ql-picker-label {
        &:hover {
          .ql-stroke {
            stroke: var(--text-max) !important;
          }
          .ql-fill {
            fill: var(--text-max) !important;
          }
        }
      }
    }
    .ql-stroke {
      fill: none !important;
      stroke: var(--text-color) !important;
    }

    .ql-fill {
      fill: var(--text-color) !important;
      stroke: none !important;
    }

    .ql-picker {
      color: var(--text-color) !important;
      &:hover {
        color: var(--text-max) !important;
      }
      .ql-picker-item {
        color: var(--text-invert) !important;
        .ql-stroke {
          stroke: var(--text-invert) !important;
        }
      }
    }
  }
  .ql-container {
    border: 1px solid var(--border-color) !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: var(--text-max) !important;
    background-color: var(--hover-color) !important;
    &.ql-disabled {
      .ql-editor {
        color: var(--text-color) !important;
        background-color: var(--border-color) !important;
        cursor: not-allowed;
      }
    }
    .ql-editor {
      font-size: var(--fs-md);
      min-height: 125px;
      &.ql-blank::before {
          color: var(--text-dim) !important;
          font-style: normal;
      }
    }
  }
}

.nodis-dd-menu {
  background: var(--hover-color) !important;
  padding: 8px 5px;
  .dropdown-item {
    color: var(--text-color);
    border-radius: 5px;
    transition: 0.3s ease-in-out;
    user-select: none;
    &:hover {
      background-color: var(--border-color);
      color: var(--text-max) !important;
    }
  }
}

.nodis-label {
  font-size: 12px;
  text-transform: capitalize;
  text-align: center;
  max-width: fit-content;
  padding: 2px 5px;
  border-radius: 4px;

  color: var(--text-max);
  border: 1px solid var(--border-color);
  background-color: var(--border-color);
  // min-width: 70px;
  &.success {
    border-color: var(--green-dark);
    color: var(--green-dark);
  }
  &.warning {
    border-color: var(--orange-dark);
    color: var(--orange-dark);
  }
  &.danger {
    border-color: var(--red-dark);
    color: var(--red-dark);
  }
  &.primary {
    border-color: var(--primary-purple);
    color: var(--primary-purple);
  }

  &.sky-blue-dark {
    border-color: var(--sky-blue-dark);
    color: var(--sky-blue-dark);
  }
}

.nodis-tabs-container {
  .nodis-tabs {
    border-bottom: 1px solid var(--border-dim);
    .tab-item {
      border-bottom: 2px solid transparent;
      padding-bottom: 5px;
      color: var(--text-color);
      text-transform: capitalize;
      font-size: 18px;
      user-select: none;
      cursor: pointer;
      &:hover {
        color: var(--text-max);
      }
      &.active {
        border-color: var(--primary-color);
        color: var(--text-max);
      }
    }
  }
}

.nodis-tag {
  background: var(--hover-color);
  padding: 5px 12px;
  border-radius: 5px;
  width: fit-content;
  &.bg-max {
    background: var(--body-color);
  }
  .remove-icon {
    width: 18px;
    height: 18px;
    path {
      fill: var(--danger-color);
      stroke: var(--danger-color);
    }
  }
}

.nodis-file-upload {
  display: inline-block;
  cursor: pointer;
}

.return-back {
  height: 40px;
  width: 40px;
  background-color: var(--base-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  transition: 0.2s ease-in-out;
  border: 2px solid var(--base-color);
  flex-shrink: 0;
  border-radius: 50%;
  &:hover {
    // background-color: var(--hover-color);
    color: var(--text-max);
  }
  svg {
    width: 24px;
  }
}

.return-back-textual {
  display: flex;
  color: var(--text-color);
  transition: 0.2s ease-in-out;
  flex-shrink: 0;
  &:hover {
    color: var(--text-max);
  }
  svg {
    width: 24px;
  }
}
