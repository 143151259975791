.table-container {
    border: 1px solid var(--border-color);
    border-radius: 4px;
}

// .home-training-center {
//     background-color: var(--hover-color);
//     border: 1px solid var(--border-color);
//     border-radius: 4px;
//     .more-images {
//         line-height: 16px;
//         border-bottom: 1px solid var(--text-dim);
//         cursor: pointer;
//     }
//     .poster-container {
//         width: 60px;
//         height: 60px;
//         border-radius: 4px;
//         background-position: center center;
//         background-size: cover;
//         background-repeat: no-repeat;
//         cursor: zoom-in;
//     }
//     .content {
//         display: flex;
//         flex-direction: column;
//         .title {
//             color: var(--text-max);
//             text-decoration: none;
//         }
//     }
// }

.home-partner {
    background-color: var(--hover-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    display: flex;
    align-items: start;
    transition: .3s ease-in;
    // cursor: pointer;
    .more-images {
        line-height: 16px;
        border-bottom: 1px solid var(--text-dim);
        cursor: pointer;
    }
    .poster-container {
        width: 60px;
        height: 60px;
        border-radius: 4px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        cursor: zoom-in;
    }
    .content {
        display: flex;
        flex-direction: column;
        line-height: 1.25em;
        .title {
            color: var(--text-max);
            text-decoration: none;
            // line-height: 1;
        }
        .desc {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.25em;
            min-height: 2.5em;
        }
    }
    &:hover {
        box-shadow: 0px 0px 5px -1px var(--primary-color);
    }
}

.home-contact-content {
    .title {
        color: var(--text-max);
    }
}

.home-services {
    background-color: var(--base-color);
    border-radius: 4px;
}
