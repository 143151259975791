.course-card {
    background-color: var(--hover-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    transition: all 300ms ease-in-out 0s;
    a {
        color: var(--text-color);
        text-decoration: none;
        &:hover {
            color: var(--text-color);
        }
    }
    .img-container {
        overflow: hidden;
        padding: 4px;
        .course-img {
            width: 100%;
            height: 200px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            transition: 0.2s all ease-in-out !important;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
        }
    }
    &:hover {
        transform: scale(1.01) translate(0px, -5px);
    }
    .hl-info {
        svg {
            path {
                stroke: var(--text-color);
            }
        }
    }
    .title {
        color: var(--text-max);
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height:1.5em;
        min-height:3em;
    }
    .card-text {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 1.5em;
        min-height: 4.5em;
    }
}
