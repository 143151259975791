.footer {
    // override global css variables to maintain colors in light-theme
    --text-color: #919eab;
    --text-max: #ffffff;
    --border-color: #2f3a46;

    background-color: var(--footer-bg);
    border-left: none;
    border-bottom: none;
    .section-heading {
        color: var(--text-max);
    }
    .f-link {
        // display: flex;
        // align-items: center;
        transition: 0.3s all ease-in-out;
        .listing-icon {
            width: 18px;
            height: 15px;
            // &.light-icon{
            //     filter: invert(100%) sepia(22%) saturate(215%) hue-rotate(331deg) brightness(112%) contrast(102%);
            // }
        }
        .f-link-item {
            color: var(--text-color);
            text-decoration: none;
            transition: 0.2s;
            cursor: pointer;
            &:hover {
                color: var(--primary-color);
            }
        }
    }

    .footer-bottom {
        border-top: 1px solid var(--border-color);
    }

    .language-icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .language-selector {
        border: 1px solid var(--border-color);
        width: fit-content;
        // padding: 5px 10px;
        .language-seletor-input {
            background-color: transparent;
            color: var(--text-color);
            width: 100px;
            border: none;
            outline: none;
            padding: 10px 0px;
            &:focus {
                outline: none;
                border: none;
                box-shadow: none;
            }
        }
    }

    .logo-container {
        width: 125px;
        .footer-brand-logo {
            width: 100%;
        }
    }

    .text {
        color: var(--text-color);
    }
    .powered {
        color: var(--text-color);
        a {
            color: var(--primary-color);
        }
    }

    .social-links {
        a {
            display: inline-block;
            line-height: 1;
            margin-right: 4px;
            border-radius: 50%;
            width: 36px;
            height: 36px;
            transition: 0.3s;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                padding: 10px;
                filter: invert(0%) sepia(1%) saturate(5%) hue-rotate(34deg) brightness(100%) contrast(100%);
            }
            &:hover {
                background: var(--primary-color);
                img {
                    filter: invert(100%) sepia(19%) saturate(0%) hue-rotate(40deg) brightness(104%) contrast(102%);
                }
            }
        }
    }
}
