.featured-content {
    height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow-y: hidden;
    .title {
        color: var(--text-max);
    }
}

.featured-section {
    padding: 0;
    font-size: 30px;
    text-align: center;
    height: 700px;
    width: 100%;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        background: linear-gradient(to top, #000 -50%, rgba(0, 0, 0, 0) 50%);
    }
    > div {
        width: 100%;
        height: 100%;
    }
    .featured-hero-data {
        // height: 100%;
        height: 700px;

        .fh-bg {
            width: 100%;
            display: inline-block;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
        }
        .featured-content-container {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                bottom: 0;
                background: linear-gradient(to right, #000 -5%, rgba(0, 0, 0, 0) 50%);

                @media only screen and (max-width: 600px) {
                    background: linear-gradient(to right, #000 -5%, rgba(0, 0, 0, 0) 150%);
                }
            }
        }
        .featured-hero-content {
            max-width: 600px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 1rem;
            align-items: start;
            text-align: left;
            z-index: 2;
            position: absolute;
            .title {
                color: #ffffff;
            }
            .desc {
                color: #dedede;
            }
        }
    }
}