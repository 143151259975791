.s-header {
    z-index: 500;
    position: absolute;
}
.header-nav {
    z-index: 600;
    font-size: 1.25rem;
    line-height: 1.875;
    height: 100%;
    width: 50%;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: all 0.5s cubic-bezier(0.7, 0.3, 0, 1);
    transition: all 0.5s cubic-bezier(0.7, 0.3, 0, 1);
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: hidden;
    &::before {
        content: "";
        position: absolute;
        top: 0px;
        background-color: var(--base-color);
        bottom: 0px;
        right: 0px;
        left: 0px;
        opacity: 0.95;
    }
}

.header-nav a:hover,
.header-nav a:focus,
.header-nav a:active {
    color: var(--primary-purple);
}

.header-nav h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.25;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    margin-top: 10px;
    padding-right: 140px;
    color: var(--primary-purple);
    position: relative;
}
.header-nav p {
    margin-bottom: 2.7rem;
}

.header-nav__content,
.header-nav h3 {
    left: 100px;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
}

.header-nav__content {
    position: relative;
    max-width: 300px;
}

.header-nav__list {
    font-weight: 600;
    line-height: 1.286;
    margin: 0 0 4.2rem 0;
    padding: 0;
    list-style: none;
    counter-reset: ctr;
}

.header-nav__list li {
    padding: 0.9rem 0;
}

.header-nav__list a {
    display: block;
    color: var(--text-color);
    position: relative;
    &.active {
        color: var(--primary-purple);
    }
}

.header-nav__close {
    z-index: 601;
    display: block;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 25px;
    right: 30px;
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
}

.header-nav__close span::before,
.header-nav__close span::after {
    content: "";
    display: block;
    height: 2px;
    width: 20px;
    background-color: var(--text-color);
    position: absolute;
    top: 50%;
    left: 7px;
    margin-top: -1px;
}

.header-nav__close span::before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.header-nav__close span::after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* menu is open
   * ----------------------------------------------- */
.menu-is-open .header-nav {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
    -webkit-overflow-scrolling: touch;
}

.menu-is-open .header-nav .header-nav__content,
.menu-is-open .header-nav h3 {
    opacity: 1;
    visibility: visible;
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
    left: 0;
}

/* ------------------------------------------------------------------- 
   * ## mobile menu toggle 
   * ------------------------------------------------------------------- */
.header-menu-toggle {
    width: 48px;
    height: 45px;
    line-height: 45px;
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
    color: #ffffff;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: fixed;
    right: 8rem;
    top: 30px;

    background: transparent;
    outline: none;
    border: none;
}

.header-menu-toggle.opaque {
    background-color: #000000;
}

.header-menu-icon {
    display: block;
    width: 26px;
    height: 2px;
    margin-top: -1px;
    right: auto;
    bottom: auto;
    background-color: var(--text-color);
    position: absolute;
    left: 11px;
    top: 50%;
}

.header-menu-icon::before,
.header-menu-icon::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: inherit;
    position: absolute;
    left: 0;
}

.header-menu-icon::before {
    top: -9px;
}

.header-menu-icon::after {
    bottom: -9px;
}

/* ------------------------------------------------------------------- 
 * responsive header
 * ------------------------------------------------------------------- */
@media only screen and (max-width: 1200px) {
    .header-logo {
        left: 60px;
    }

    .header-menu-toggle {
        right: 60px;
    }
}

@media only screen and (max-width: 900px) {
    .header-nav h3 {
        padding-right: 0;
    }

    .header-nav h3::after {
        display: none;
    }

    .header-nav__content {
        max-width: 280px;
    }

    .header-nav__list li {
        padding: 0.6rem 0;
    }
}

@media only screen and (max-width: 800px) {
    .header-logo {
        left: 40px;
    }

    .header-nav {
        width: calc(100% - 80px);
    }

    .header-menu-toggle {
        right: 40px;
    }
}

@media only screen and (max-width: 600px) {
    .header-menu-toggle {
        top: 27px;
        right: 25px;
    }
}

@media only screen and (max-width: 400px) {
    .header-logo {
        left: 30px;
    }
    .header-logo img {
        width: 150px;
        height: 35px;
    }
    .header-nav {
        width: calc(100% - 40px);
    }
    .header-nav h3 {
        margin-bottom: 3rem;
    }
    .header-menu-toggle {
        top: 15px;
        right: 20px;
    }
}

/* menu is open
 * ----------------------------------------------- */
.menu-is-open.header-nav {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
    -webkit-overflow-scrolling: touch;
}

.menu-is-open.header-nav .header-nav__content,
.menu-is-open.header-nav h3 {
    opacity: 1;
    visibility: visible;
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
    left: 0;
}
