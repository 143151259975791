.banner-slider {
    height: 300px;
    .slick-slide {
        height: 300px;
        &:before {
            content: "";
            background: unset !important;
        }
    }
    .banner-slide {
        height: 100%;
        .slide-bg {
            width: 100%;
            display: inline-block;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        &:hover {
            .slide-title {
                bottom: 0;
            }
        }
    }
}

.mySwiper {
    height: 300px;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    // background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    // object-fit: cover;
}

.slide-title {
    position: absolute;
    // bottom: 0;
    width: 100%;
    background: var(--hover-transparent);
    color: var(--text-max);
    font-size: 14px;
    padding: 5px;
    bottom: -100%;
    transition: bottom 0.3s ease;
}